<template>
  <div>
    <button
      type="button"
      @click="print"
      class="btn btn-sm btn-dark float-right mr-6 d-print-none"
    >
      Print
    </button>
    <div class="book">
      <div class="page">
        <div class="subpage">
          <div class="row justify-content-center ">
            <div class="col-3">
              <div style="height:100px;" class="d-flex justify-content-center align-items-center">
                  <div>
                    <img
                      src="https://balangankab.go.id/icon.png"
                      class=""
                      style="height:100px;"
                      alt="no found"
                    />
                  </div>
              </div>
            </div>
            <div class="col-9">
              <p
                class="pl-3 text-center"
                style="font-size: 21px; line-height: 1.5; line-spacing: 3px"
              >
                PEMERINTAH KABUPATEN BALANGAN
                <br /><span class="font-bold"
                  >DINAS KOMUNIKASI DAN INFORMATIKA</span
                >
                <br />
              </p>
              <p class="text-center" style="font-size: 15px">
                Jl. Jend. Ahmad Yani Km. 3,5 Paringin Selatan Telp. (0526)
                2028434
              </p>
            </div>
          </div>
          <img
            src="@/static/photo/line.jpg"
            class="img-fluid"
            style="width: 100%; height: 20px"
          />
          <!-- START BODY -->
          
          
          <!-- END BODY -->
        </div>
          <div style="position:absolute;bottom:100px;right:100px;">
            <div class="text-center">
                Tanda Tangan
            </div>
          </div>
        <!-- FOOTER -->
        <!-- FOOTER -->
      </div>

      <div class="page">
        <div class="subpage text-justify">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
          adipisci voluptatibus autem dolor vel sequi quod consequatur, voluptas
          nulla deleniti alias assumenda explicabo beatae odit corrupti et
          incidunt inventore sunt!Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Iure adipisci voluptatibus autem dolor vel sequi
          quod consequatur, voluptas nulla deleniti alias assumenda explicabo
          beatae odit corrupti et incidunt inventore sunt!Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Iure adipisci voluptatibus autem
          dolor vel sequi quod consequatur, voluptas nulla deleniti alias
          assumenda explicabo beatae odit corrupti et incidunt inventore
          sunt!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
          adipisci voluptatibus autem dolor vel sequi quod consequatur, voluptas
          nulla deleniti alias assumenda explicabo beatae odit corrupti et
          incidunt inventore sunt!Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Iure adipisci voluptatibus autem dolor vel sequi
          quod consequatur, voluptas nulla deleniti alias assumenda explicabo
          beatae odit corrupti et incidunt inventore sunt!Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Iure adipisci voluptatibus autem
          dolor vel sequi quod consequatur, voluptas nulla deleniti alias
          assumenda explicabo beatae odit corrupti et incidunt inventore
          sunt!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
          adipisci voluptatibus autem dolor vel sequi quod consequatur, voluptas
          nulla deleniti alias assumenda explicabo beatae odit corrupti et
          incidunt inventore sunt!Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Iure adipisci voluptatibus autem dolor vel sequi
          quod consequatur, voluptas nulla deleniti alias assumenda explicabo
          beatae odit corrupti et incidunt inventore sunt!Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Iure adipisci voluptatibus autem
          dolor vel sequi quod consequatur, voluptas nulla deleniti alias
          assumenda explicabo beatae odit corrupti et incidunt inventore
          sunt!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
          adipisci voluptatibus autem dolor vel sequi quod consequatur, voluptas
          nulla deleniti alias assumenda explicabo beatae odit corrupti et
          incidunt inventore sunt!Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Iure adipisci voluptatibus autem dolor vel sequi
          quod consequatur, voluptas nulla deleniti alias assumenda explicabo
          beatae odit corrupti et incidunt inventore sunt!Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Iure adipisci voluptatibus autem
          dolor vel sequi quod consequatur, voluptas nulla deleniti alias
          assumenda explicabo beatae odit corrupti et incidunt inventore
          sunt!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
          adipisci voluptatibus autem dolor vel sequi quod consequatur, voluptas
          nulla deleniti alias assumenda explicabo beatae odit corrupti et
          incidunt inventore sunt!Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Iure adipisci voluptatibus autem dolor vel sequi
          quod consequatur, voluptas nulla deleniti alias assumenda explicabo
          beatae odit corrupti et incidunt inventore sunt!Lorem ipsum dolor sit
          amet, consectetur adipisicing elit. Iure adipisci voluptatibus autem
          dolor vel sequi quod consequatur, voluptas nulla deleniti alias
          
        </div>
           <!-- FOOTER -->
            <div style="position:absolute;bottom:100px;right:100px;">
            <div class="text-center">
                Tanda Tangan
            </div>
          </div>
        <!-- FOOTER -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    print() {
      window.print();
    },
  },
};
</script>
<style scoped>
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 12pt "Tahoma";
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 210mm;
  /* min-height: 297mm;  */
  min-height: 457mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position:relative;
}
.subpage {
  /* padding: 1cm; */
  /* border: 5px red solid; */
  position:relative;

  height: 297mm;
  /* outline: 2cm #FFEAEA solid; */
}
.d-footer-printer-ttd{
  display:none;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  .table-print {
    border: 1px solid black;
  }

  .d-footer-printer-ttd{
    display:block;
    position:absolute;
    bottom:150px;
    right:100px;
  }
}
</style>